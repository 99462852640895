<script lang="ts" setup></script>

<template>
  <v-app>
    <MainSidebarOld />
    <ContactSidebar />
    <v-main>
      <v-container class="py-0 px-4 d-block fill-height">
        <AppHeader />
        <slot />
      </v-container>
      <!-- <Toolbox /> -->
      <!-- <AppFooter /> -->
    </v-main>
  </v-app>
</template>

<style lang="scss">
// :deep() {
//   .v-application {
//     background: rgb(var(--v-theme-background));
//     background: linear-gradient(
//       45deg,
//       rgb(var(--v-theme-background)) 0%,
//       rgb(var(--v-theme-surface)) 100%
//     );
//     background-attachment: fixed;
//   }
// }
</style>
